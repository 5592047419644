import { render, staticRenderFns } from "./nav-title.vue?vue&type=template&id=5729eb65&scoped=true&functional=true&"
import script from "./nav-title.vue?vue&type=script&lang=ts&"
export * from "./nav-title.vue?vue&type=script&lang=ts&"
import style0 from "./nav-title.vue?vue&type=style&index=0&id=5729eb65&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "5729eb65",
  null
  
)

export default component.exports