











































































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import GraphLegend from '@/components/visual-navigator/graph/graph-legend.vue';

import * as zoom from 'd3-zoom';
import {select} from 'd3-selection';
import GraphStronglyConnectedComponent
    from '@/components/visual-navigator/graph/graph-strongly-connected-component.vue';
import {StoreMixin} from '@/mixins/StoreMixin';
import ViewVertex from '@/components/visual-navigator/graph/view-vertex';
import ViewGraph from '@/components/visual-navigator/graph/view-graph';
import ViewEdge from '@/components/visual-navigator/graph/view-edge';

@Component({
    components: {
        GraphStronglyConnectedComponent,
        GraphLegend
    }
})
export default class Graph extends Mixins(StoreMixin) {
    @Prop()
    public centerVertex!: ViewVertex;
    @Prop()
    public selectedVertices!: ViewVertex[];

    @Prop({default: false})
    public optionShowFailingEdges!: boolean;

    @Prop({default: true})
    public optionHighlightTrustingNodes!: boolean;

    @Prop({default: true})
    public optionHighlightTrustedNodes!: boolean;

    @Prop({default: true})
    public optionShowRegularEdges!: boolean;

    @Prop({default: true})
    public optionTransitiveQuorumSetOnly!: boolean;


    @Prop({default: false})
    public fullScreen!: boolean;

    @Prop({default: true})
    public isLoading!: boolean;

    @Prop()
    public viewGraph!: ViewGraph;

    public d3svg!: any;
    public d3Grid!: any; //used for zooming and panning
    public graphZoom!: any;

    @Watch('centerVertex')
    public onCenterNodeChanged() {
        this.centerCorrectVertex();
    }

    @Watch('fullScreen')
    public onFullScreenChanged() {
        this.centerCorrectVertex();
        this.transformAndZoom();
    }

    @Watch('isLoading')
    public onIsLoadingChanged() {
        this.centerCorrectVertex();
    }

    vertexSelected(vertex: ViewVertex) {
        this.$emit('vertex-selected', vertex);
    }

    getVertexTransform(vertex:ViewVertex): string {
        return `translate(${vertex.x},${vertex.y})`;
    }

    getVertexTextRectWidth(vertex:ViewVertex) {
        return (this.$options!.filters!.truncate(vertex.label, 10).length / 10) * 70;
    }

    getVertexTextRectWidthPx(vertex:ViewVertex) {
        return this.getVertexTextRectWidth(vertex) + 'px';
    }

    getVertexTextRectX(vertex: ViewVertex) {
        return '-' + this.getVertexTextRectWidth(vertex) / 2 + 'px';
    }

    getVertexTextClass(vertex:ViewVertex) {
        return {
            active: !vertex.isFailing,
            failing: vertex.isFailing,
            selected: vertex.selected
        };
    }

    getVertexClassObject(vertex:ViewVertex) {
        return {
            active: !vertex.isFailing,
            selected: vertex.selected,
            failing: vertex.isFailing,
            target: this.highlightVertexAsIncoming(vertex) && !vertex.selected,
            source: this.highlightVertexAsOutgoing(vertex) && !vertex.selected && !this.highlightVertexAsIncoming(vertex),
            transitive: vertex.isPartOfTransitiveQuorumSet
        };
    }

    highlightVertexAsOutgoing(vertex: ViewVertex) {
        if (this.selectedVertices.length <= 0)
            return false;
        let edges = this.selectedVertices.map(selectedVertex => this.viewGraph.viewEdges.get(vertex.key + ':' + selectedVertex.key)).filter(edge => edge !== undefined);
        let allEdgesAreFailing = edges.every(edge => edge!.isFailing);

        if (edges.length <= 0)
            return false;

        return vertex.isTrustingSelectedVertex && this.optionHighlightTrustingNodes && (!allEdgesAreFailing || this.optionShowFailingEdges);
    }

    highlightVertexAsIncoming(vertex: ViewVertex) {
        if (this.selectedVertices.length <= 0)
            return false;

        let edges = this.selectedVertices.map(selectedVertex => this.viewGraph.viewEdges.get(selectedVertex.key + ':' + vertex.key)).filter(edge => edge !== undefined);
        let allEdgesAreFailing = edges.every(edge => edge!.isFailing);

        if (edges.length <= 0)
            return false;

        return vertex.isTrustedBySelectedVertex && this.optionHighlightTrustedNodes && (!allEdgesAreFailing || this.optionShowFailingEdges);
    }

    width() {
        return (this.$refs.graphSvg as SVGElement).clientWidth;
    }

    height() {
        return (this.$refs.graphSvg as SVGElement).clientHeight;
    }

    get dimmerClass() {
        return {
            dimmer: true,
            active: this.isLoading,
        };
    }

    public centerCorrectVertex() {
        if (this.centerVertex !== undefined) {
            const realVertexX = -this.centerVertex!.x + this.width() / 2;
            const realVertexY = -this.centerVertex!.y + this.height() / 2;

            let transform = zoom.zoomIdentity.translate(realVertexX, realVertexY).scale(1);
            this.d3svg.call(this.graphZoom.transform, transform);
        }
    }

    public mounted() {
        this.d3Grid = select(this.$refs.grid as Element);
        this.d3svg = select(this.$refs.graphSvg as Element);
        this.graphZoom = zoom.zoom().on('zoom', (event) => {
            this.d3Grid.attr('transform', event.transform);
        }).scaleExtent([1, 3]);
        this.transformAndZoom();
    }

    transformAndZoom() {
        let transform = zoom.zoomIdentity.translate(this.width() / 2, this.height() / 2).scale(1);
        this.d3svg.call(this.graphZoom)
            .call(this.graphZoom.transform, transform);
    }

    getEdgeClassObject(edge: ViewEdge) {
        return {
            'strongly-connected': edge.isPartOfStronglyConnectedComponent,
            'failing': edge.isFailing,
        };
    }

    getEdgePath(edge: ViewEdge) {
        return 'M' +
            edge.source.x + ' ' +
            edge.source.y + ' L' +
            edge.target.x + ' ' +
            edge.target.y;
    }
}
