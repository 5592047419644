










































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import NavTitle from '@/components/side-bar/nav-title.vue';
import {
    BIcon,
    BIconPlus,
    BIconDownload,
    BIconChevronRight,
    BIconChevronDown,
    BIconLightning,
    BIconLightningFill,
    BIconGearWide,
    BIconGear,
    BIconSearch,
    BBadge
} from 'bootstrap-vue';

@Component({
    components: {
        NavTitle, BIcon, BIconPlus, BIconDownload, BIconChevronRight, BIconChevronDown, BIconLightning, BIconGear, BIconSearch,
        BIconLightningFill,
        BIconGearWide, BBadge
    }
})
export default class NavLink extends Vue {
    @Prop()
    title!: string;

    @Prop({default: false})
    showSubTitle!: boolean;

    @Prop()
    subTitle!: string;

    @Prop({default: false})
    showDropdownToggle!: boolean;

    @Prop({default: false})
    dropDownShowing!: boolean;

    @Prop({default: false})
    isLinkInDropdown!: boolean;

    @Prop({default: false})
    hasWarnings!: boolean;

    @Prop()
    warnings!: string;

    @Prop({default: false})
    hasDanger!: boolean;

    //do we want the title also red
    @Prop({default:true})
    completeDanger!: boolean;

    @Prop()
    dangers!: string;

    @Prop({default: false})
    showIcon!: boolean;

    @Prop({})
    icon!: string;

    @Prop({default: false})
    secondary!: boolean;

    hover: boolean = false;

    get titleClass(): any {
        return {
            'secondary': this.secondary
        };
    }

    get chevronDirection(): any {
        if (this.dropDownShowing)
            return 'chevron-down';
        else return 'chevron-right';
    }

    get classObject(): any {
        return {
            'sb-nav-dropdown-toggle': false,
            'sb-nav-link': !this.isLinkInDropdown,
            'sb-nav-dropdown-link': this.isLinkInDropdown
        };
    }

    get dropdownClass(): any {
        return {
            'right-end': !this.showDropdownToggle,
            'right': this.showDropdownToggle
        };
    }
}
