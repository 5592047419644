
















































export default {
    name: 'graph-legend',
    data() {
        return {
            showLegend: false,
        };
    },
};
