













import {BPagination} from 'bootstrap-vue';

export default {
    name: 'nav-pagination',
    props: {
        value: {},
        totalRows: {}
    },
    components:{BPagination}
};
