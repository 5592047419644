








import Store from '@/store/Store';
import {BIconX} from 'bootstrap-vue';

export default {
    name: 'simulation-badge',
    components: {BIconX: BIconX},
    computed: {
        store():Store {
            return (this as any).$root.$data.store;
        }
    }
};
